// Our main CSS
import '../css/app.css'

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
const vueSingleFileComponents = import.meta.glob('./**/*.vue', { eager: true })
import { createApp } from 'vue'
const app = createApp({})
for (const path in vueSingleFileComponents) {
  const importedModule = vueSingleFileComponents[path].default
  const name = path?.split('/').pop()?.split('.')[0]
  if (name) {
    app.component(name, importedModule)
  }
}
app.mount('#vue-app')

import { inView, scroll, animate } from 'motion'
import Alpine from 'alpinejs'

const CLASS_IS_CHANGING = 'is-changing'
const CLASS_IS_CHANGING_OUT = 'is-changing-out'
const CLASS_IS_CHANGING_IN = 'is-changing-in'

window.Alpine = Alpine

const transitionPage = async (
  pageUrl?: string,
  pageId?: number | null,
  target: HTMLElement | null = document.body.parentElement ?? null
) => {
  const html = document.body.parentElement

  if (!html || !pageUrl || !pageId || !target) return

  document.body.parentElement?.classList.add(CLASS_IS_CHANGING, CLASS_IS_CHANGING_OUT)

  const pageHTML: string = await fetch(
    `${window.wp_ajax_url ?? ''}?action=render_page&id=${pageId}`,
    {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
  ).then((response) => response.text())

  console.log('changing')

  html.addEventListener(
    'transitionend',
    () => {
      console.log('changed out')

      const prevPageEl = document.createElement('div')
      const prevPageUrl = window.location.href

      prevPageEl.classList.add('prev-page')
      prevPageEl.innerHTML = target.innerHTML

      target.innerHTML = pageHTML
      target.appendChild(prevPageEl)

      window.history.pushState({}, '', pageUrl)
      target.offsetHeight

      html.addEventListener(
        'transitionend',
        () => {
          console.log('changed in')

          html.classList.remove(CLASS_IS_CHANGING, CLASS_IS_CHANGING_IN)
          prevPageEl.remove()

          window.addEventListener('popstate', () => {
            window.location.href = prevPageUrl
          })
        },
        {
          capture: false,
          once: true,
          passive: false
        }
      )

      html.classList.remove(CLASS_IS_CHANGING_OUT)
      html.classList.add(CLASS_IS_CHANGING_IN)
    },
    {
      capture: false,
      once: true,
      passive: false
    }
  )

  html.classList.add(CLASS_IS_CHANGING, CLASS_IS_CHANGING_OUT)
}

document.addEventListener('alpine:init', () => {
  Alpine.data('frontpage', () => ({
    load: (event: MouseEvent) => {
      event.preventDefault()
      const target = event.target as HTMLAnchorElement
      const pageUrl = target?.getAttribute('href')
      const pageId = parseInt(target?.dataset.pageId || '')

      const pageTargetEl = document.body.querySelector('main')

      if (!pageUrl) return

      target.closest('.fp-page-header-panel')?.classList.add('is-active')

      transitionPage(pageUrl, pageId, pageTargetEl)
    }
  }))
})

// Add scroll class to body when scrolling

let scrollDeboundeTimer: number | null = null

document.addEventListener('scroll', () => {
  if (scrollDeboundeTimer) {
    window.clearTimeout(scrollDeboundeTimer)
  }

  scrollDeboundeTimer = setTimeout(onScroll, 300)
})

const onScroll = () => {
  document.body.classList.add('has-scrolled')
}

// Start Alpine.js

Alpine.start()

// Initiate

document.addEventListener('DOMContentLoaded', () => {
  const pageHeaderEl = document.querySelector('.page-header')

  if (pageHeaderEl) {
    const pageHeaderImgEl = pageHeaderEl?.querySelector('.page-header-image img')

    if (pageHeaderImgEl) {
      scroll(
        animate(pageHeaderImgEl as HTMLImageElement, {
          transform: ['translateY(0)', 'translateY(50%)']
        }),
        {
          target: pageHeaderEl,
          offset: ['start start', 'end start']
        }
      )
    }
  }

  inView('[data-block="image-text"]', ({ target }) => {
    animate(
      target.querySelector('img') as HTMLImageElement,
      {
        opacity: [0, 1],
        transform: ['translateY(25%)', 'none']
      },
      {
        delay: 0.25,
        duration: 1
      }
    )
  })
})

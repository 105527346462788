<template>
  <div v-if="logos" class="logo-swiper-container w-full absolute z-10">
    <swiper
      ref="swiper"
      class="logo-slider w-full"
      v-bind="swiperOptions"
      @mouseenter="stopAutoplay"
      @mouseleave="startAutoplay"
      @swiper="onSwiper"
    >
      <swiper-slide v-for="(logo, index) in logos" :key="index" class="swiper-slide w-auto h-auto">
        <img
          width="200"
          height="95"
          class="lazy"
          :src="logo.image"
          :alt="logo.title ?? 'Partner logotype'"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts">
// import { defineComponent, reactive } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

import { Autoplay } from 'swiper/modules'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    logos: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      swiper: null,
      swiperOptions: {
        modules: [Autoplay],
        grabCursor: true,
        spaceBetween: 0,
        centeredSlides: true,
        speed: 6000,
        autoplay: {
          delay: 0,
          disableOnInteraction: false
        },
        loop: true,
        slidesPerView: 'auto',
        loopedSlides: 1,
        breakpoints: {
          300: {
            loopedSlides: 1
          },
          500: {
            loopedSlides: 2
          }
        }
      }
    }
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper
    },
    stopAutoplay() {
      if (this.swiper) this.swiper.autoplay.stop()
    },
    startAutoplay() {
      this.swiper ? this.swiper.autoplay.start() : ''
    }
  }
}
</script>

<style lang="postcss">
.logo-swiper-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.logo-slider > .swiper-wrapper {
  will-change: transform;
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  position: relative;
}
.logo-slider > .swiper-wrapper > .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  position: relative;
  overflow: hidden;
}
</style>
